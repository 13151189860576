var digimarc = {

  // Gallery
  gallery_num_slides: 0,
  gallery_movement_direction: 0, // -1 moving left, +1 moving right
  gallery_center_width_change_mobile: (240 - 195) + (6 - 3), // Pixel value of the difference in width change
  gallery_center_width_change_desktop: (530 - 396) + (10 - 5), // Pixel value of the difference in width change

  setupGallery: function() {
    var self = this;
    var $gallery = $(".gallery").not('.no-carousel');

    if( $gallery.length > 0 ){
      this.gallery_num_slides = $gallery.find(".gallery-item").length;

      $gallery.slick({
        accessibility: false,
        arrows: false,
        prevArrow: "",
        nextArrow: "",
        centerMode: true,
        centerPadding: '10px',
        focusOnSelect: true,
        variableWidth: true,
        slidesToShow: 5,
        speed: 500,
        draggable: false,
        responsive: [
        {
          breakpoint: 768,
          settings: {}
        }
        ]
      });

      // Refresh positions for IE
      setTimeout(function() {
        $gallery.slick('setPosition');
      },500);

      // On before slide change
      $gallery.on('beforeChange', function(event, slick, currentSlide, nextSlide){
        console.log("gallery - beforeChange currentSlide:"+currentSlide+" nextSlide:"+nextSlide);


        // Make sure we are moving slides
        if (currentSlide != nextSlide) {

          // Determine the movement direction of the gallery
          self.gallery_movement_direction = -1; // left / backwards

          if (nextSlide == (currentSlide+1)%self.gallery_num_slides 
            || nextSlide == (currentSlide+2)%self.gallery_num_slides 
            || nextSlide == (currentSlide+3)%self.gallery_num_slides ) {
            self.gallery_movement_direction = 1; // right / forwards
        }

          // Get the number of center slides
          var num_center_slides_before = $gallery.find(".slick-center").length;

          // Wait for slick to set its target translate
          //  Then we are going to override it
          setTimeout(function() {
            // Get the current translate3d X value
            var style = $gallery.find(".slick-track").attr("style");
            var pos1 = style.indexOf("translate3d(") + "translate3d(".length;
              var pos2 = style.indexOf("px", pos1);
              var translate3d_x = parseInt(style.substring(pos1, pos2));

            // Get the offset based on breakpoint
            var gallery_movement_x_offset = 0;
            if ($(window).width() < 768)
              gallery_movement_x_offset = (self.gallery_movement_direction * self.gallery_center_width_change_mobile * 0.5);
            else
              gallery_movement_x_offset = (self.gallery_movement_direction * self.gallery_center_width_change_desktop * 0.5);
            
            // Get the number of center slides
            var num_center_slides_during = $gallery.find(".slick-center").length;

            // Determine some extra offset when there is wrapping of the carousel
            if (self.gallery_movement_direction == 1) { // left to right
              if (num_center_slides_during == 2 && num_center_slides_before != 2) {
                var center_slides_diff = num_center_slides_before - num_center_slides_during;
                gallery_movement_x_offset = (gallery_movement_x_offset * center_slides_diff);
              }
              // else just keep the normal value
            }
            else { // right to left
              if (num_center_slides_before == 2 && num_center_slides_during != 2) {
                var center_slides_diff = num_center_slides_during - num_center_slides_before;
                gallery_movement_x_offset = (gallery_movement_x_offset * center_slides_diff);
              }
              // else just keep the normal value
            }

            // Set the new translate
            translate3d_x = translate3d_x + gallery_movement_x_offset;

            // Set the new translate value
            $gallery.find(".slick-track").css("transform", "translate3d("+translate3d_x+"px, 0px, 0px)");
          }, 20)
        }
      });
    }
  },

  setupContactModal: function(){
    $('.contact-modal').on('click', function(){
      console.log('clicked the modal button');
      digimarc.launchContactModal($('.form-modal'));//$(this).data('modal-content'), $(this).data('modal-service'));
      return false;
    })
  },

  launchContactModal: function(modal) {
    var ContactModal = function(modal) {
      this.$currentDiv = $(modal);
      this.$readySection = this.$currentDiv.find('.ready-section');
      this.$startSection = this.$currentDiv.find('.start-section');
      this.$formSection = this.$currentDiv.find('.form-section');
      this.$thanksSection = this.$currentDiv.find('.thanks-section');
      this.openModal(modal);
    }
    ContactModal.prototype.animateSections = function($sectionFadesOut, $sectionFadesIn) {
      $sectionFadesIn = $sectionFadesIn || null;
      $sectionFadesOut.stop(true,true).animate({'opacity':0}, 200, function(){
        $sectionFadesOut.removeClass('showing');
        if ($sectionFadesIn !== null) {
          $sectionFadesIn.addClass('showing').css({'opacity':0}).stop(true,true).animate({'opacity':1}, 200);
        }
      });
    }
    ContactModal.prototype.openModal = function() {
      // scroll to top on open
      $('html, body').animate({scrollTop: '0'}, 800, "easeOutCubic");
      var thisModal = this;
      this.$currentDiv.stop(true,true).addClass('showing').animate({'opacity':1});
      this.$currentDiv.find('.close-modal').on('click', function(){
        thisModal.closeModal();
      });
      this.$currentDiv.find('.start-form').on('click', function(){
        thisModal.showForm();
        return false;
      });
      /*
      this.$currentDiv.find('.form-section form').on('submit', function() {
        thisModal.formSubmit();
        return false;
      });
*/
    }
    ContactModal.prototype.closeModal = function() {
      var thisModal = this;
      this.animateSections(this.$currentDiv);
    }
    ContactModal.prototype.showForm = function() {
      var thisModal = this;
      this.animateSections(this.$startSection, this.$formSection);
    }
    ContactModal.prototype.formSubmit = function() {
      var thisModal = this;
      this.animateSections(this.$formSection);
      this.animateSections(this.$readySection, this.$thanksSection);
    }
    var newModal = new ContactModal(modal);
  },


  setupModal: function() {
    $('.btn-modal').on('click',function(){
      digimarc.launchModal($(this).data('modal-content'), $(this).data('modal-service'))
    })
  },

  launchModal: function(modalContent, modalService) {
    var Modal = function(vidID, tubeOrVimeo) {
      this.randomID = 'id'+Math.round(Math.random(500)*1000);
      this.openModal(vidID, tubeOrVimeo);
    }
    Modal.prototype.createModalHTML = function(vidID, tubeOrVimeo) {
      var modalHTML = "<div class='modal' id='"+this.randomID+"' style='display:block;opacity:0;'>";
      modalHTML += "<div class='close-modal'></div>";
      modalHTML += "<div class='modal-container'>";
      if (tubeOrVimeo == "vimeo") {
        modalHTML += "<iframe class='video' src='https://player.vimeo.com/video/"+vidID+"?color=ffffff&title=0&byline=0&portrait=0' width='1000' height='563' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>";
      } else {
        modalHTML += "<iframe class='video' width='1000' height='563' src='https://www.youtube.com/embed/"+vidID+"?rel=0&amp;showinfo=0&amp;autoplay=0' frameborder='0' allowfullscreen></iframe>";
      }
      modalHTML += "</div></div>";
      return modalHTML;
    }
    Modal.prototype.openModal = function(vidID, tubeOrVimeo) {
      var thisModal = this;
      $('body').append(this.createModalHTML(vidID, tubeOrVimeo));
      $('#'+this.randomID).stop(true,true).animate({'opacity':1});
      $('#'+this.randomID).on('click', function(){
        thisModal.closeModal();
      });
    }
    Modal.prototype.closeModal = function() {
      var $currentDiv = $('#'+this.randomID);
      $currentDiv.stop(true,true).animate({'opacity':0}, 100, function(){
        $currentDiv.remove();
      });
    }
    var newModal = new Modal(modalContent, modalService);
  },

  // Perfect scrollbar
  setupPerfectScrollbar: function() {
    if (typeof $(".perfect-scroll").perfectScrollbar !== "undefined") {
      var $perfectScroll = $(".perfect-scroll");
      $perfectScroll.perfectScrollbar();
      var increment = $(window).width();
      var currentHorzScroll = 0;
      var navWidth = digimarc.perfectScrolllistWidth($perfectScroll);
      $('.nav-wrap').find('.glyphicon-chevron-left').on('click', function() { 
        currentHorzScroll -= increment;
        if (currentHorzScroll <= 0) {
          currentHorzScroll = 0;
        }
        $perfectScroll.animate({'scrollLeft':currentHorzScroll});
      });
      $('.nav-wrap').find('.glyphicon-chevron-right').on('click', function() { 
        currentHorzScroll += increment;
        if (currentHorzScroll > navWidth) {
          currentHorzScroll = navWidth;
        }
        $perfectScroll.animate({'scrollLeft':currentHorzScroll});
      });
    }
  },

  perfectScrolllistWidth: function($scrollSection) {
    var $scrollSectionUL = $scrollSection.find('ul');
    var realWidth = 0;
    $.each($scrollSectionUL.find('li'), function(key, value){
      realWidth += $(value).outerWidth(true);
    });
    return realWidth;
  },

  // select tag
  setupInputSelect: function() {
    $("select").change(function () {
      var selected = $(this).find("option:selected").text();
      if (selected.length > 0) {
        $("select").siblings(".placeholder").hide();
      }
      else {
        $("select").siblings(".placeholder").show();
      }
    });
  },

  // input search
  setupInputSearch: function() {
    // Add listener for focus in and out of text field
    $(".input-search").focus(function(e) {
      $(this).addClass("active");
    }).focusout(function(e) {
      // On unfocus and if there is no value reset css
      if ($(this).val().length == 0) {
        $(this).removeClass("active");
      }
    });
  },

  // Start application nav bar animation
  animateApplicationNav: function() {
    $(".application-nav.delayed-open, .blog-nav.delayed-open").addClass("opening");
  },

  // Setup Main Carousel
  setupMainCarousel: function() {
    if ( $("#main-carousel").length > 0 ) {
      $("#main-carousel").carousel({
        interval: false
      });

      // Animate the text for the current slide
      if ($("#main-carousel .item.active").find("h3").length > 0)
        $("#main-carousel .item.active").find("h3").shuffleLetters();
      if ($("#main-carousel .item.active").find("h4").length > 0)
        $("#main-carousel .item.active").find("h4").shuffleLetters();

      // Setup the text animation for each new slide
      $("#main-carousel").on('slide.bs.carousel', function (e) {
        if ($(e.relatedTarget).find("h3").length > 0)
          $(e.relatedTarget).find("h3").shuffleLetters();
        if ($(e.relatedTarget).find("h4").length > 0)
          $(e.relatedTarget).find("h4").shuffleLetters();
      });

    }
  },

  // Start scrambler
  scrambleText: function () {
    // do scrambling
    $(".main-carousel").each(function(index) {
      $(".carousel-caption h3").css('display', 'block');
      $(".carousel-caption h3").shuffleLetters();
    });
  },

  //Application template subnav items
  initApplicationSubNav: function() {
    var self = this;
    var header_height = ($(window).width() >= 768 ? $(".application-page-nav").height() : $("header").height() );
    $.each($('.application-page-nav li a'), function(key, value) {
      $(value).click(function(e) {
        e.preventDefault();
        smoothSectionSlide($(value).attr('href'));
      });
    });
    function smoothSectionSlide(destinationID) {
      var slide_to_top = $("#"+destinationID).position().top - header_height + 1;
      $('html, body').animate({scrollTop: slide_to_top}, 800, "easeOutCubic");
    }
  },

  initCenterLists: function() {
    $.each($('.scan-item'), function(){
      $.each($(this).find('ul.centered-list'), function(key, unorderedList) {
        var newWidth = 0;
        $.each($(this).find('li'), function(key, listItem) {
          newWidth = newWidth < $(listItem).outerWidth(true) ? $(listItem).outerWidth(true) : newWidth;
        });
        $(unorderedList).css({'width':Math.ceil(newWidth+2)+'px'});
      });
    });
  },

  initFormStyleFix: function() {
    // loop through forms
    $.each($('form'), function() {
      // loop each label;
      var $form = $(this);
      $.each($form.find('label'), function(key, label) {
        digimarc.labelFormPlaceholderReplace(label);
      });
      $.each($form.find('select'), function(key, select){
        digimarc.selectFormStyleFix($(select));
      });
      $.each($form.find('input[type="submit"]'), function(key, button){
        $(this).addClass('btn btn-green btn-wide');
      });
      $.each($form.find('input[type="checkbox"]'), function(key, button){
        digimarc.checkboxFormStyleFix($(this));
      });
      $.each($form.find('input[type="radio"]'), function(key, button){
        digimarc.radioFormStyleFix($(this));
      });
    });
  },

  labelFormPlaceholderReplace: function(label) {
    if($('#'+$(label).attr('for')+'[type="text"]').length > 0 ) {
      $('#'+$(label).attr('for')+'[type="text"]').attr('placeholder',$(label).text());
      $(label).hide();
    }
    if($('textarea#'+$(label).attr('for')).length > 0 ) {
      $('textarea#'+$(label).attr('for')).attr('placeholder',$(label).text()).attr('rows','1');
      $(label).hide();
    }
  },

  selectFormStyleFix: function($select) {
    $("label[for='"+$select.attr('id')+"']").hide();
    var newSelectHTML = "<div class='select-wrapper'><span class='placeholder'></span><span class='sort fa fa-sort'></span>";
    newSelectHTML += "<select id="+$select.attr('id')+" name="+$select.attr('name')+">";
    newSelectHTML += $select.html();
    newSelectHTML += "</select>";
    newSelectHTML += "</div>";
    $select.before(newSelectHTML);
    $select.remove();
  },

  checkboxFormStyleFix: function($checkbox) {
    $checkbox.addClass('checkbox-custom');
    $checkboxLabel = $("label[for='"+$checkbox.attr('id')+"']");
    $checkboxLabelText = $checkboxLabel.text();
    checkboxHTML = '<span class="box fa fa-square-o"></span><span class="check fa fa-check"></span>';
    checkboxHTML += $checkboxLabelText;
    $checkboxLabel.addClass('checkbox-label').html(checkboxHTML);
  },

  radioFormStyleFix: function($radio) {
    $radio.addClass('radio-custom');
    $radioLabel = $("label[for='"+$radio.attr('id')+"']");
    $radioLabelText = $radioLabel.text();
    radioHTML = '<span class="box fa fa-genderless"></span><span class="check fa fa-circle"></span>';
    radioHTML += $radioLabelText;
    $radioLabel.addClass('radio-label').html(radioHTML);
  },
}


/**
 * Document Ready
 */
 $(document).ready(function() {
  // Main Carousel
  digimarc.setupMainCarousel();

  // Prepare Modal
  digimarc.setupModal();

  // Prepare contact modal
  digimarc.setupContactModal();

  // Gallery
  digimarc.setupGallery();

  // Setup scrollbars
  digimarc.setupPerfectScrollbar();

  // Setup select tags
  digimarc.setupInputSelect();

  // Setup input search
  digimarc.setupInputSearch();
  
  // Start opening application nav bar
  digimarc.animateApplicationNav();

  // Initialize buttons in application subnav
  digimarc.initApplicationSubNav();

  // Center lists on 3 across sections in application
  digimarc.initCenterLists();

  // fixing the styles for the contact form. Sitefinity is not so great with this.
  digimarc.initFormStyleFix();
});


