/**
 * Digimarc Scrolling Javascript
 *  Parallaxing actions and other scroll based
 *  animations or triggers.
 */
 var digimarc_scrolling = {
  // Scroll magic controller
  controller: null,

  // Initialization
  init: function() {

    // Init the scroll magic controller
    this.controller = new ScrollMagic.Controller();

    // Setup the parallax
    this.setupParallaxCarousel();
    this.setupParallaxBGs();
    this.setupParallaxSectionSpecific();

    // Setup the fixed nav
    this.setupStickyApplicationPageNav();

    // Setup application page subnav
    this.setupApplicationSubNav();

    // Setup the text descrambler when going into application-section-05
    this.setupApplicationScrambledText();

    // Setup opacity transition for carousel controls
    this.setupHomeCarouselControls();
  },

  // Parallax setup
  setupParallaxCarousel: function() {
    var self = this;

    //return; // Disabling this parallax effect

    // Home Page Jumbotron Carousel
    $(".parallax-carousel").each(function(index) {
      // Create the scene
      var scene = new ScrollMagic.Scene({
        triggerElement: $(this),
        triggerHook: 0, // top of viewport
        duration: "100%"
      })
      .setTween(".parallax-carousel .carousel-inner", {y: "75%", ease: "linear"})
      //.addIndicators()
      .addTo(self.controller);
    });
  },
  setupParallaxBGs: function() {
    var self = this;

    // Jumbotron / top of page parallax
    $(".parallax-bg").each(function(index) {
      if(!$(".parallax-bg").hasClass("disabled") ) {
        var parallax_type = $(this).attr("parallax-type");

        // "top"
        if (typeof parallax_type === "undefined" || parallax_type == "top") {
          // Create the scene
          var scene = new ScrollMagic.Scene({
            triggerElement: $(this).parent(),
            triggerHook: 0, // top of viewport
            duration: "100%"
          })
          .setTween($(this), {y: "75%", ease: "linear"})
          //.addIndicators()
          .addTo(self.controller);
        }
        else if (parallax_type == "middle") {
          var speed;
          if($(".parallax-bg").hasClass("slow")) {
            speed = 4;
          } else {
            speed = 2;
          }
          // Create the scene
          var scene = new ScrollMagic.Scene({
            triggerElement: $(this).parent(),
            triggerHook: 1, // bottom of viewport
            duration: function() {
              return $(window).height() * speed;
            }
          })
          .setTween($(this), {y: "50%", ease: "linear"})
          //.addIndicators()
          .addTo(self.controller);
        }
        else {
          // Unknown parallax type
        }
      }
    });
  },
  // Adds a fade to the controls
  setupHomeCarouselControls: function() {
    var self = this;
    // start when top of screen is halfway down carousel
    //  reduce opacity of control items inside carousel.
    var h = $("#main-carousel").height() * .4;
    var offsetH = $("#main-carousel").height() * .6;
    new ScrollMagic.Scene({
      triggerElement: "#main-carousel",
      duration: h,
    })
    .offset(offsetH)
    .setTween($("#main-carousel .carousel-control, .carousel-indicators"), {opacity: 0.0})
    //.addIndicators()
    .addTo(self.controller);
  },
  setupParallaxSectionSpecific: function() {
    var self = this;

    // Generic img parallax
    $(".parallax-generic-classadd").each(function(index) {
      // Create the scene
      var scene = new ScrollMagic.Scene({
        triggerElement: $(this).parent(),
        triggerHook: 0.5, // middle of viewport
        duration: 0
      })
      .on("enter", function(e) {
        var img_el = $(e.target.triggerElement()).find(".parallax-generic-classadd");
        img_el.addClass("parallax");
      })
      .on("leave", function(e) {
        if (e.scrollDirection === "REVERSE") {
          var img_el = $(e.target.triggerElement()).find(".parallax-generic-classadd");
          img_el.removeClass("parallax");
        }
      })
      //.setTween($(this), {y: "0px", x: "0px", ease: "easeOutCubic"})
      //.addIndicators()
      .addTo(self.controller);
    });
  },

  // Setup fixed nav bar on application page
  setupStickyApplicationPageNav: function() {
    var self = this;
    
    $(".application-page-nav-placeholder").each(function(index) {
      var scene = new ScrollMagic.Scene({
        triggerElement: $(this),
        triggerHook: 0, // top of viewport
        duration: 0
      })
      .on("enter", function(e) {
        $(".application-page-nav-placeholder").addClass("fixed");
        $(".application-page-nav").addClass("fixed");
      })
      .on("leave", function(e) {
        if (e.scrollDirection === "REVERSE") {
          $(".application-page-nav-placeholder").removeClass("fixed");
          $(".application-page-nav").removeClass("fixed");
        }
      })
      //.addIndicators()
      .addTo(self.controller);
    });
  },

  // Application subnav
  setupApplicationSubNav: function() {
    var self = this;
    // Overview
    new ScrollMagic.Scene({
      triggerElement: "#overview-section",
      duration: function() {
        return ($("#overview-section").height()*2);
      }
    })
    .setClassToggle("#overview-nav", "active")
    // .addIndicators()
    .addTo(self.controller);

    // How it works
    new ScrollMagic.Scene({
      triggerElement: "#works-section",
      duration: function() {
        return ($("#works-section").height());
      }
    })
    .setClassToggle("#works-nav", "active")
    // .addIndicators()
    .addTo(self.controller);

    // Customers
    new ScrollMagic.Scene({
      triggerElement: "#customers-section",
      duration: function() {
        return ($("#customers-section").height());
      }
    })
    .setClassToggle("#customers-nav", "active")
    // .addIndicators()
    .addTo(self.controller);

    // Pricing
    new ScrollMagic.Scene({
      triggerElement: "#pricing-section",
      duration: function() {
        return ($("#pricing-section").height());
      }
    })
    .setClassToggle("#pricing-nav", "active")
    // .addIndicators()
    .addTo(self.controller);

    // Support
    // new ScrollMagic.Scene({
    //   triggerElement: "#support-section",
    //   duration: function() {
    //     return ($("#support-section").height());
    //   }
    // })
    // .setClassToggle("#support-nav", "active")
    // .addIndicators()
    // .addTo(self.controller);

  },

  // Applcation section 05 scrambled text
  setupApplicationScrambledText: function() {
    var self = this;

    // Add scrambled text effect
    $(".application-section-05").each(function(index) {
      var scene = new ScrollMagic.Scene({
        triggerElement: $(this),
        triggerHook: 0.5, // middle of viewport
      })
      .on("enter", function(e) { 
        $(".application-section-05 h1").show();
        $(".application-section-05 h1").shuffleLetters();

        e.target.destroy();
      })
      //.addIndicators()
      .addTo(self.controller)
    });
  },

};

/**
 * Document Ready
 */
 $(document).ready(function() {
  digimarc_scrolling.init();
});