/**
 * Digimarc Header Javascript
 */
var digimarc_header = {
  // Initialization
  init: function() {
    this.setupHeaderSearch();
    this.setupHeaderMenu();
    this.setupHeaderSubNavs();
  },

  // Header Search
  setupHeaderSearch: function() {
    // Search toggle
    $("header .search").click(function (e) {
      e.preventDefault();

      // If the search is closed, open it
      if ($("header .search").hasClass("expanded") == false) {
        $("body").addClass("scroll-lock"); // Lock body scrolling
        $("header .search").addClass("expanded");
        $("header .search-expanded").addClass("expanded");
        $("header .search-expanded").slideDown(250);
      }
      else { // Search is open, so close
        $("body").removeClass("scroll-lock");
        $("header .search").removeClass("expanded");
        $("header .search-expanded").removeClass("expanded");
        $("header .search-expanded").slideUp(250, function() {
          // After the search is closed reset it
          $("header .search-expanded").css("display", "");
          $("header .search-expanded input").val("");
          $("header .search-expanded .suggestions").removeClass("active");
        });
      }
    });

    // Add listener for focus in and out of text field
    $("header .search-expanded input").keyup(function(e) {
      if ($(this).val().length > 0) {
        $("header .search-expanded .suggestions").addClass("active");
      }
      else {
        $("header .search-expanded .suggestions").removeClass("active");
      }
    });
  },

  // Header menu (mobile)
  setupHeaderMenu: function() {
    // Menu toggle
    $("header .menu").click(function (e) {
      e.preventDefault();

      // If the menu is closed, open it
      if ($("header .menu").hasClass("expanded") == false) {
        $("body").addClass("scroll-lock-mobile"); // Lock body scrolling
        $("header .menu").addClass("expanded");
        $("header nav").addClass("expanded");
        $("header nav").slideDown(250);
      }
      else { // Menu is open, so close
        $("body").removeClass("scroll-lock-mobile");
        $("header .menu").removeClass("expanded");
        $("header nav").removeClass("expanded");
        $("header nav").slideUp(250, function() {
          // After the menu is closed reset it
          $("header nav").removeClass("nav-subitems-expanded");
          $("header nav").css("display", "")
          $("header nav > ul > li.expanded > ul").slideUp(250);
          $("header nav > ul > li.expanded").removeClass("expanded");
        });
      }
    });
  },

  // Header Sub navs
  setupHeaderSubNavs: function() {
    // Subnav selections
    $("header nav > ul > li > a").click(function (e) {

      if ($(window).width() < 768) {
        var parent_li_el = $(this).parent();

        // If this item has subitems
        if (parent_li_el.find("ul").length > 0) {
          e.preventDefault();

          // if the subitems are closed, open
          if (parent_li_el.hasClass("expanded") == false) {
            // Collapse any other nav subitems that are open
            $("header nav > ul > li.expanded > ul").slideUp(250);
            $("header nav > ul > li.expanded").removeClass("expanded");

            // Expand the nav subitems
            $("header nav").addClass("nav-subitems-expanded");
            parent_li_el.addClass("expanded");
            parent_li_el.find("ul").slideDown(250);
          }
          else { // the subitems are open, so close
            $("header nav").removeClass("nav-subitems-expanded");
            parent_li_el.removeClass("expanded");
            parent_li_el.find("ul").slideUp(250);
          }
        }
      }
      // else this item does not have sub items
      //  the link should act normally
    });
  }
};

/**
 * Document Ready
 */
$(document).ready(function() {
  digimarc_header.init();
});